 <template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit'">
      <span @click="toTypeUrl('shoplist_xq')" id="fzcolor">店铺详情</span>
      <span @click="toTypeUrl('shoplist_xq_shop')">报备商户号信息</span>
    </div>
    <div class="header" v-else>
      <span id="fzcolor">基本信息</span>
      <span @click="toUrl('add_shoplist_js')">结算信息</span>
      <span @click="toUrl('add_shoplist_pz')">配置信息</span>
      <span @click="toUrl('add_shoplist_syr')">受益人信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">基本信息</h5>
    </div>
    <div
      class="input_con"
      v-show="
        formData.merchantType == 'ENTERPRISE' ||
        formData.merchantType == 'INDIVIDUAL_BUSINESS'
      "
    >
      <ul class="inputBox">
        <li>
          <span><i>*</i>店铺名称</span>
          <el-input
            class="inputs"
            v-model="formData.storeName"
            maxlength="128"
            placeholder="输入店铺名称"
            tabindex="1"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>所属商户</span>
          <el-select
            :disabled="pageType == 'edit' ? true : false"
            class="inputs"
            v-model="formData.mchId"
            placeholder="选择所属商户"
            tabindex="2"
          >
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>店铺简称</span>
          <el-input
            class="inputs"
            v-model="formData.storeShortName"
            maxlength="128"
            placeholder="输入店铺简称"
            tabindex="3"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>店铺内部ID</span>
          <el-input
            class="inputs"
            v-model="formData.internalId"
            maxlength="128"
            placeholder="输入店铺内部"
            tabindex="4"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>客服电话</span>
          <el-input
            class="inputs"
            v-model="formData.servicePhone"
            maxlength="128"
            placeholder="输入客服电话"
            tabindex="5"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>经营类目</span>
          <div class="inputLine">
            <el-select
              class="inputs citys"
              v-model="category2"
              @change="cateChange(1)"
              placeholder="选择类目"
              tabindex="6"
            >
              <el-option
                v-for="(v, i) in category1Option"
                :key="i"
                :label="i"
                :value="i"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="category3"
              @change="cateChange(2)"
              placeholder="选择类目"
              tabindex="7"
            >
              <el-option
                v-for="(v, i) in category2Option"
                :key="i"
                :label="i"
                :value="i"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.category"
              placeholder="选择类目"
              tabindex="8"
            >
              <el-option
                v-for="(v, i) in category3Option"
                :key="i"
                :label="v['二级类目']"
                :value="v['编号']"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span><i>*</i>店铺经营地址</span>
          <el-input
            class="inputs"
            v-model="formData.storeAddress"
            maxlength="128"
            placeholder="输入店铺经营地址"
            tabindex="9"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>地区</span>
          <div class="inputLine">
            <el-select
              class="inputs citys"
              v-model="formData.province"
              @change="getAddress(1)"
              placeholder="选择省"
              tabindex="10"
            >
              <el-option
                v-for="(v, i) in provinceOption"
                :key="i"
                :label="v.province"
                :value="v.provinceCode"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.city"
              @change="getAddress(2)"
              placeholder="选择市"
              tabindex="11"
            >
              <el-option
                v-for="(v, i) in cityOption"
                :key="i"
                :label="v.city"
                :value="v.cityCode"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.county"
              placeholder="选择区"
              tabindex="12"
            >
              <el-option
                v-for="(v, i) in countyOption"
                :key="i"
                :label="v.county"
                :value="v.countyCode"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span><i>*</i>店铺主体类型</span>
          <el-select
            class="inputs"
            v-model="formData.merchantType"
            placeholder="选择店铺主体类型"
            tabindex="13"
            @change="merchantTypeChange()"
          >
            <el-option
              v-for="(v, i) in merchantType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>店铺证件类型</span>
          <el-select
            class="inputs"
            v-model="formData.licenceType"
            placeholder="选择店铺证件类型"
            tabindex="14"
          >
            <el-option
              v-for="(v, i) in licenceType"
              :key="i"
              :label="v"
              :value="i + 1"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>证件编号</span>
          <el-input
            class="inputs"
            v-model="formData.licenceNo"
            maxlength="128"
            placeholder="输入证件编号"
            tabindex="15"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>注册地址</span>
          <el-input
            class="inputs"
            v-model="formData.companyAddress"
            maxlength="128"
            placeholder="输入注册地址"
            tabindex="16"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>营业执照有效期</span>
          <div class="inputLine">
            <el-date-picker
              class="inputs pick"
              v-model="formData.licenceStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker
              class="inputs pick"
              v-model="formData.licenceEndDate"
              :picker-options="licenceOptions"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li>
          <span><i>*</i>法人姓名</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonName"
            maxlength="128"
            placeholder="输入法人姓名"
            tabindex="17"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>法人证件类型</span>
          <el-select
            class="inputs"
            v-model="formData.legalPersonCemType"
            placeholder="选择法人证件类型"
            tabindex="18"
          >
            <el-option
              v-for="(v, i) in legalPersonCemType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>法人证件有效期</span>
          <div class="inputLine">
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemEndDate"
              :picker-options="PersonOptions"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li>
          <span><i>*</i>法人证件号码</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonCemNo"
            maxlength="128"
            placeholder="输入法人证件号码"
            tabindex="19"
          ></el-input>
        </li>
        <li>
          <span ><i v-show="formData.merchantType == 'ENTERPRISE'">*</i>法人证件照地址</span>
            <el-input
            class="inputs"
            v-model="formData.idAddress"
            maxlength="128"
            placeholder="输入法人证件照地址"
            tabindex=""
          ></el-input>
        </li>
        <li>
          <span ><i>*</i>法人为受益人</span>
          <el-select
            class="inputs"
            v-model="formData.legalPersonIsBene"
            placeholder="请选择"
            tabindex=""
          >
            <el-option
              v-for="(v,i) in legalPersonIsBene"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span ><i>*</i>法人为联系人</span>
          <el-select  @change="selectChanged($event)"
            class="inputs"
            v-model="formData.legalPersonIsContact"
            placeholder="请选择"
            tabindex=""
          >
            <el-option
              v-for="(v,i) in legalPersonIsContact"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
         <li>
          <span><i>*</i>联系人</span>
          <el-input :disabled="formData.legalPersonIsContact == 1 ? true : false"
            class="inputs"
            v-model="formData.contactName"
            maxlength="128"
            placeholder="输入联系人"
            tabindex="20"
          ></el-input>
        </li>  
        <li>
          <span><i>*</i>联系人手机</span>
          <el-input
            class="inputs"
            v-model="formData.contactPhone"
            maxlength="11"
            placeholder="输入联系人手机"
            tabindex="21"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系人邮箱</span>
          <el-input
            class="inputs"
            v-model="formData.contactEmail"
            maxlength="128"
            placeholder="输入联系人邮箱"
            tabindex="22"
          ></el-input>
        </li>  
        <li>
          <span><i>*</i>联系人证件类型</span>
          <el-select :disabled="formData.legalPersonIsContact == 1 ? true : false"
            class="inputs"
            v-model="formData.contactCemType"
            placeholder="选择联系人证件类型"
            tabindex="23"
          >
            <el-option
              v-for="(v, i) in legalPersonCemType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>联系人证件号码</span>
          <el-input :disabled="formData.legalPersonIsContact == 1 ? true : false"
            class="inputs"
            v-model="formData.contactIdCard"
            maxlength="128"
            placeholder="输入联系人证件号码"
            tabindex="24"
          ></el-input>
        </li>  
        <li>
          <span ><i>*</i>联系人证件有效期</span>
          <div class="inputLine">
            <el-date-picker :disabled="formData.legalPersonIsContact == 1 ? true : false"
              class="inputs pick"
              v-model="formData.contactValidStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker :disabled="formData.legalPersonIsContact == 1 ? true : false"
              class="inputs pick"
              v-model="formData.contactValidSEndDate"
              :picker-options="contactValidSEndDateFV"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li>
          <span>店铺支付宝账户</span>
          <el-input
            class="inputs"
            v-model="formData.alipayId"
            maxlength="128"
            placeholder="输入店铺支付宝账户"
            tabindex="25"
          ></el-input>
        </li>
        <li>
          <span>联系人微信账号</span>
          <el-input
            class="inputs"
            v-model="formData.contactWechat"
            maxlength="128"
            placeholder="输入联系人微信账号"
            tabindex="26"
          ></el-input>
        </li>
      </ul>
    </div>
    <div
      class="upload_ph"
      v-show="
        formData.merchantType == 'ENTERPRISE' ||
        formData.merchantType == 'INDIVIDUAL_BUSINESS'
      "
    >
      <div class="uploader1">
        <h4><i>*</i>营业执照</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="licencePictureSuccess"
        >
          <img
            v-if="formData.licencePicture"
            :src="formData.licencePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.licencePicture" class="avatar-uploader-span" @click.stop="formData.licencePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证正面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardNormalPictureSuccess"
        >
          <img
            v-if="formData.idcardNormalPicture"
            :src="formData.idcardNormalPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardNormalPicture" class="avatar-uploader-span" @click.stop="formData.idcardNormalPicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardReversePictureSuccess"
        >
          <img
            v-if="formData.idcardReversePicture"
            :src="formData.idcardReversePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardReversePicture" class="avatar-uploader-span" @click.stop="formData.idcardReversePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.legalPersonIsContact != 1">*</i>联系人证件正面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="contactIdNomalPicSuccess"
        >
          <img
            v-if="formData.contactIdNomalPic"
            :src="formData.contactIdNomalPic"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.contactIdNomalPic" class="avatar-uploader-span" @click.stop="formData.contactIdNomalPic = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.legalPersonIsContact != 1">*</i>联系人证件反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="contactIdReversePicSuccess"
        >
          <img
            v-if="formData.contactIdReversePic"
            :src="formData.contactIdReversePic"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.contactIdReversePic" class="avatar-uploader-span" @click.stop="formData.contactIdReversePic = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.legalPersonIsContact != 1">*</i>业务办理授权函</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="authorizationLetterSuccess"
        >
          <img
            v-if="formData.authorizationLetter"
            :src="formData.authorizationLetter"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.authorizationLetter" class="avatar-uploader-span" @click.stop="formData.authorizationLetter = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
    </div>
    <div class="input_con"
      v-show="
        formData.merchantType == 'PUBLIC_INSTITUTION' ||
        (formData.merchantType != 'ENTERPRISE' &&
          formData.merchantType != 'INDIVIDUAL_BUSINESS' &&
          formData.merchantType != 'MICRO_ENTERPRISE')
      "
    >
      <ul class="inputBox">
        <li>
          <span><i>*</i>店铺名称</span>
          <el-input
            class="inputs"
            v-model="formData.storeName"
            maxlength="128"
            placeholder="输入店铺名称"
            tabindex="1"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>所属商户</span>
          <el-select
            class="inputs"
            :disabled="pageType == 'edit' ? true : false"
            v-model="formData.mchId"
            placeholder="选择所属商户"
            tabindex="2"
          >
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>店铺简称</span>
          <el-input
            class="inputs"
            v-model="formData.storeShortName"
            maxlength="128"
            placeholder="输入店铺简称"
            tabindex="3"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>店铺内部ID</span>
          <el-input
            class="inputs"
            v-model="formData.internalId"
            maxlength="128"
            placeholder="输入店铺内部ID"
            tabindex="4"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>客服电话</span>
          <el-input
            class="inputs"
            v-model="formData.servicePhone"
            maxlength="128"
            placeholder="输入客服电话"
            tabindex="5"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>经营类目</span>
          <div class="inputLine">
            <el-select
              class="inputs citys"
              v-model="category2"
              @change="cateChange(1)"
              placeholder="选择类目"
              tabindex="6"
            >
              <el-option
                v-for="(v, i) in category1Option"
                :key="i"
                :label="i"
                :value="i"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="category3"
              @change="cateChange(2)"
              placeholder="选择类目"
              tabindex="7"
            >
              <el-option
                v-for="(v, i) in category2Option"
                :key="i"
                :label="i"
                :value="i"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.category"
              placeholder="选择类目"
              tabindex="8"
            >
              <el-option
                v-for="(v, i) in category3Option"
                :key="i"
                :label="v['二级类目']"
                :value="v['编号']"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span><i>*</i>店铺经营地址</span>
          <el-input
            class="inputs"
            v-model="formData.storeAddress"
            maxlength="128"
            placeholder="输入店铺经营地址"
            tabindex="9"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>地区</span>
          <div class="inputLine">
            <el-select
              class="inputs citys"
              v-model="formData.province"
              @change="getAddress(1)"
              placeholder="选择省"
              tabindex="10"
            >
              <el-option
                v-for="(v, i) in provinceOption"
                :key="i"
                :label="v.province"
                :value="v.provinceCode"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.city"
              @change="getAddress(2)"
              placeholder="选择市"
              tabindex="11"
            >
              <el-option
                v-for="(v, i) in cityOption"
                :key="i"
                :label="v.city"
                :value="v.cityCode"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.county"
              placeholder="选择区"
              tabindex="12"
            >
              <el-option
                v-for="(v, i) in countyOption"
                :key="i"
                :label="v.county"
                :value="v.countyCode"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span><i>*</i>店铺主体类型</span>
          <el-select
            class="inputs"
            v-model="formData.merchantType"
            placeholder="选择主体类型"
            tabindex="13"
          >
            <el-option
              v-for="(v, i) in merchantType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>店铺证件类型</span>
          <el-select
            class="inputs"
            v-model="formData.licenceType"
            placeholder="选择店铺证件类型"
            tabindex="14"
          >
            <el-option
              v-for="(v, i) in licenceType"
              :key="i"
              :label="v"
              :value="i + 1"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>证件编号</span>
          <el-input
            class="inputs"
            v-model="formData.licenceNo"
            maxlength="128"
            placeholder="输入证件编号"
            tabindex="15"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>注册地址</span>
          <el-input
            class="inputs"
            v-model="formData.companyAddress"
            maxlength="128"
            placeholder="输入注册地址"
            tabindex="16"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>登记证件有效期</span>
          <div class="inputLine">
            <el-date-picker
              class="inputs pick"
              v-model="formData.licenceStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker
              class="inputs pick"
              v-model="formData.licenceEndDate"
              :picker-options="licenceOptions"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li>
          <span><i>*</i>法人姓名</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonName"
            maxlength="128"
            placeholder="输入法人姓名"
            tabindex="18"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>法人证件类型</span>
          <el-select
            class="inputs"
            v-model="formData.legalPersonCemType"
            placeholder="选择法人证件类型"
            tabindex="17"
          >
            <el-option
              v-for="(v, i) in legalPersonCemType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>法人证件有效期</span>
          <div class="inputLine">
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemEndDate"
              :picker-options="PersonOptions"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>            
          </div>
        </li>
        <li>
          <span><i>*</i>法人证件号码</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonCemNo"
            maxlength="128"
            placeholder="输入法人证件号码"
            tabindex="19"
          ></el-input>
        </li>
        <li>
          <span ><i v-show="formData.merchantType == 'ENTERPRISE'">*</i>法人证件照地址</span>
            <el-input
            class="inputs"
            v-model="formData.idAddress"
            maxlength="128"
            placeholder="输入法人证件照地址"
            tabindex=""
          ></el-input>
        </li>
        <li>
          <span ><i>*</i>法人为受益人</span>
          <el-select
            class="inputs"
            v-model="formData.legalPersonIsBene"
            placeholder="请选择"
            tabindex=""
          >
            <el-option
              v-for="(v,i) in legalPersonIsBene"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span ><i>*</i>法人为联系人</span>
          <el-select @change="selectChanged($event)"
            class="inputs"
            v-model="formData.legalPersonIsContact"
            placeholder="请选择"
            tabindex=""
          >
            <el-option
              v-for="(v,i) in legalPersonIsContact"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>联系人</span>
          <el-input :disabled="formData.legalPersonIsContact == 1 ? true : false"
            class="inputs"
            v-model="formData.contactName"
            maxlength="128"
            placeholder="输入联系人"
            tabindex="20"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系人手机</span>
          <el-input
            class="inputs"
            v-model="formData.contactPhone"
            maxlength="11"
            placeholder="输入联系人手机"
            tabindex="21"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系人邮箱</span>
          <el-input
            class="inputs"
            v-model="formData.contactEmail"
            maxlength="128"
            placeholder="输入联系人邮箱"
            tabindex="22"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系人证件类型</span>
          <el-select :disabled="formData.legalPersonIsContact == 1 ? true : false"
            class="inputs"
            v-model="formData.contactCemType"
            placeholder="选择联系人证件类型"
            tabindex="23"
          >
            <el-option
              v-for="(v, i) in legalPersonCemType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>联系人证件号码</span>
          <el-input :disabled="formData.legalPersonIsContact == 1 ? true : false"
            class="inputs"
            v-model="formData.contactIdCard"
            maxlength="128"
            placeholder="输入联系人证件号码"
            tabindex="24"
          ></el-input>
        </li>
        <li>
          <span ><i>*</i>联系人证件有效期</span>
          <div class="inputLine">
            <el-date-picker :disabled="formData.legalPersonIsContact == 1 ? true : false"
              class="inputs pick"
              v-model="formData.contactValidStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker :disabled="formData.legalPersonIsContact == 1 ? true : false"
              class="inputs pick"
              v-model="formData.contactValidSEndDate"
              :picker-options="contactValidSEndDateFV"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li>
          <span>店铺支付宝账户</span>
          <el-input
            class="inputs"
            v-model="formData.alipayId"
            maxlength="128"
            placeholder="输入店铺支付宝账户"
            tabindex="25"
          ></el-input>
        </li>
        <li>
          <span>联系人微信账号</span>
          <el-input
            class="inputs"
            v-model="formData.contactWechat"
            maxlength="128"
            placeholder="输入联系人微信账户"
            tabindex="26"
          ></el-input>
        </li>
      </ul>
    </div>
    <div class="upload_ph"
      v-show="
        formData.merchantType == 'PUBLIC_INSTITUTION' ||
        (formData.merchantType != 'ENTERPRISE' &&
          formData.merchantType != 'INDIVIDUAL_BUSINESS' &&
          formData.merchantType != 'MICRO_ENTERPRISE')
      "
    >
      <div class="uploader1">
        <h4><i>*</i>证件照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="licencePictureSuccess"
        >
          <img
            v-if="formData.licencePicture"
            :src="formData.licencePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.licencePicture" class="avatar-uploader-span" @click.stop="formData.licencePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>单位证明函</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="licenceCompanyPictureSuccess"
        >
          <img
            v-if="formData.licenceCompanyPicture"
            :src="formData.licenceCompanyPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.licenceCompanyPicture" class="avatar-uploader-span" @click.stop="formData.licenceCompanyPicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证正面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardNormalPictureSuccess"
        >
          <img
            v-if="formData.idcardNormalPicture"
            :src="formData.idcardNormalPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardNormalPicture" class="avatar-uploader-span" @click.stop="formData.idcardNormalPicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardReversePictureSuccess"
        >
          <img
            v-if="formData.idcardReversePicture"
            :src="formData.idcardReversePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardReversePicture" class="avatar-uploader-span" @click.stop="formData.idcardReversePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.legalPersonIsContact != 1">*</i>联系人证件正面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="contactIdNomalPicSuccess"
        >
          <img
            v-if="formData.contactIdNomalPic"
            :src="formData.contactIdNomalPic"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.contactIdNomalPic" class="avatar-uploader-span" @click.stop="formData.contactIdNomalPic = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.legalPersonIsContact != 1">*</i>联系人证件反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="contactIdReversePicSuccess"
        >
          <img
            v-if="formData.contactIdReversePic"
            :src="formData.contactIdReversePic"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.contactIdReversePic" class="avatar-uploader-span" @click.stop="formData.contactIdReversePic = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.legalPersonIsContact != 1">*</i>业务办理授权函</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="authorizationLetterSuccess"
        >
          <img
            v-if="formData.authorizationLetter"
            :src="formData.authorizationLetter"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.authorizationLetter" class="avatar-uploader-span" @click.stop="formData.authorizationLetter = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
    </div>
    <div class="input_con" v-show="formData.merchantType == 'MICRO_ENTERPRISE'">
      <ul class="inputBox">
        <li>
          <span><i>*</i>店铺名称</span>
          <el-input
            class="inputs"
            v-model="formData.storeName"
            maxlength="128"
            placeholder="输入店铺名称"
            tabindex="1"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>所属商户</span>
          <el-select
            :disabled="pageType == 'edit' ? true : false"
            class="inputs"
            v-model="formData.mchId"
            placeholder="选择所属商户"
            tabindex="2"
          >
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>店铺简称</span>
          <el-input
            class="inputs"
            v-model="formData.storeShortName"
            maxlength="128"
            placeholder="输入店铺简称"
            tabindex="3"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>店铺内部ID</span>
          <el-input
            class="inputs"
            v-model="formData.internalId"
            maxlength="128"
            placeholder="输入店铺内部ID"
            tabindex="4"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>客服电话</span>
          <el-input
            class="inputs"
            v-model="formData.servicePhone"
            maxlength="128"
            placeholder="输入客服电话"
            tabindex="5"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>经营类目</span>
          <div class="inputLine">
            <el-select
              class="inputs citys"
              v-model="category2"
              @change="cateChange(1)"
              placeholder="选择类目"
              tabindex="6"
            >
              <el-option
                v-for="(v, i) in category1Option"
                :key="i"
                :label="i"
                :value="i"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="category3"
              @change="cateChange(2)"
              placeholder="选择类目"
              tabindex="7"
            >
              <el-option
                v-for="(v, i) in category2Option"
                :key="i"
                :label="i"
                :value="i"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.category"
              placeholder="选择类目"
              tabindex="8"
            >
              <el-option
                v-for="(v, i) in category3Option"
                :key="i"
                :label="v['二级类目']"
                :value="v['编号']"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span><i>*</i>店铺经营地址</span>
          <el-input
            class="inputs"
            v-model="formData.storeAddress"
            maxlength="128"
            placeholder="输入店铺经营地址"
            tabindex="9"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>地区</span>
          <div class="inputLine">
            <el-select
              class="inputs citys"
              v-model="formData.province"
              @change="getAddress(1)"
              placeholder="选择省"
              tabindex="10"
            >
              <el-option
                v-for="(v, i) in provinceOption"
                :key="i"
                :label="v.province"
                :value="v.provinceCode"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.city"
              @change="getAddress(2)"
              placeholder="选择市"
              tabindex="11"
            >
              <el-option
                v-for="(v, i) in cityOption"
                :key="i"
                :label="v.city"
                :value="v.cityCode"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.county"
              placeholder="选择区"
              tabindex="12"
            >
              <el-option
                v-for="(v, i) in countyOption"
                :key="i"
                :label="v.county"
                :value="v.countyCode"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span><i>*</i>店铺主体类型</span>
          <el-select
            class="inputs"
            v-model="formData.merchantType"
            placeholder="选择主体类型"
            tabindex="13"
            @change="merchantTypeChange()"
          >
            <el-option
              v-for="(v, i) in merchantType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>小微经营类型</span>
          <el-select
            class="inputs"
            v-model="formData.microType"
            placeholder="选择小微经营类型"
            tabindex="14"
          >
            <el-option
              v-for="(v, i) in microType"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>店铺名称</span>
          <el-input
            class="inputs"
            v-model="formData.shopName"
            maxlength="128"
            placeholder="输入店铺名称"
            tabindex="15"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>店铺地址</span>
          <el-input
            class="inputs"
            v-model="formData.shopAddress"
            maxlength="128"
            placeholder="输入店铺地址"
            tabindex="16"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>法人姓名</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonName"
            maxlength="128"
            placeholder="输入法人姓名"
            tabindex="18"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>法人证件类型</span>
          <el-select
            class="inputs"
            v-model="formData.legalPersonCemType"
            placeholder="选择法人证件类型"
            tabindex="17"
          >
            <el-option
              v-for="(v, i) in legalPersonCemType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>法人证件有效期</span>
          <div class="inputLine">
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemEndDate"
              :picker-options="PersonOptions"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>            
          </div>
        </li>
        <li>
          <span><i>*</i>法人证件号码</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonCemNo"
            maxlength="128"
            placeholder="输入法人证件号码"
            tabindex="19"
          ></el-input>
        </li>
        <li>
          <span ><i v-show="formData.merchantType == 'ENTERPRISE'">*</i>法人证件照地址</span>
            <el-input
            class="inputs"
            v-model="formData.idAddress"
            maxlength="128"
            placeholder="输入法人证件照地址"
            tabindex=""
          ></el-input>
        </li>
        <li>
          <span ><i>*</i>法人为受益人</span>
          <el-select
            class="inputs"
            v-model="formData.legalPersonIsBene"
            placeholder="请选择"
            tabindex=""
          >
            <el-option
              v-for="(v,i) in legalPersonIsBene"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span ><i>*</i>法人为联系人</span>
          <el-select
            class="inputs" @change="selectChanged($event)"
            v-model="formData.legalPersonIsContact"
            placeholder="请选择"
            tabindex=""
          >
            <el-option
              v-for="(v,i) in legalPersonIsContact"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>联系人</span>
          <el-input :disabled="formData.legalPersonIsContact == 1 ? true : false"
            class="inputs"
            v-model="formData.contactName"
            maxlength="128"
            placeholder="输入联系人"
            tabindex="20"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系人手机</span>
          <el-input
            class="inputs"
            v-model="formData.contactPhone"
            maxlength="11"
            placeholder="输入联系人手机"
            tabindex="21"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系人邮箱</span>
          <el-input
            class="inputs"
            v-model="formData.contactEmail"
            maxlength="128"
            placeholder="输入联系人邮箱"
            tabindex="22"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系人证件类型</span>
          <el-select :disabled="formData.legalPersonIsContact == 1 ? true : false"
            class="inputs"
            v-model="formData.contactCemType"
            placeholder="选择联系人证件类型"
            tabindex="23"
          >
            <el-option
              v-for="(v, i) in legalPersonCemType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>联系人证件号码</span>
          <el-input :disabled="formData.legalPersonIsContact == 1 ? true : false"
            class="inputs"
            v-model="formData.contactIdCard"
            maxlength="128"
            placeholder="输入联系人证件号码"
            tabindex="24"
          ></el-input>
        </li>
        <li>
          <span ><i>*</i>联系人证件有效期</span>
          <div class="inputLine">
            <el-date-picker :disabled="formData.legalPersonIsContact == 1 ? true : false"
              class="inputs pick"
              v-model="formData.contactValidStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker :disabled="formData.legalPersonIsContact == 1 ? true : false"
              class="inputs pick"
              v-model="formData.contactValidSEndDate"
              :picker-options="contactValidSEndDateFV"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li>
          <span>店铺支付宝账户</span>
          <el-input
            class="inputs"
            v-model="formData.alipayId"
            maxlength="128"
            placeholder="输入店铺支付宝账户"
            tabindex="25"
          ></el-input>
        </li>
        <li>
          <span>联系人微信账号</span>
          <el-input
            class="inputs"
            v-model="formData.contactWechat"
            maxlength="128"
            placeholder="输入联系人微信账号"
            tabindex="26"
          ></el-input>
        </li>
      </ul>
    </div>
    <div class="upload_ph" v-show="formData.merchantType == 'MICRO_ENTERPRISE'">
      <div class="uploader1">
        <h4><i>*</i>店铺门头照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="shopEntrancePictureSuccess"
        >
          <img
            v-if="formData.shopEntrancePicture"
            :src="formData.shopEntrancePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.shopEntrancePicture" class="avatar-uploader-span" @click.stop="formData.shopEntrancePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>店内环境照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="shopIndoorPictureSuccess"
        >
          <img
            v-if="formData.shopIndoorPicture"
            :src="formData.shopIndoorPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.shopIndoorPicture" class="avatar-uploader-span" @click.stop="formData.shopIndoorPicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证正面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardNormalPictureSuccess"
        >
          <img
            v-if="formData.idcardNormalPicture"
            :src="formData.idcardNormalPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardNormalPicture" class="avatar-uploader-span" @click.stop="formData.idcardNormalPicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardReversePictureSuccess"
        >
          <img
            v-if="formData.idcardReversePicture"
            :src="formData.idcardReversePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardReversePicture" class="avatar-uploader-span" @click.stop="formData.idcardReversePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.legalPersonIsContact != 1">*</i>联系人证件正面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="contactIdNomalPicSuccess"
        >
          <img
            v-if="formData.contactIdNomalPic"
            :src="formData.contactIdNomalPic"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.contactIdNomalPic" class="avatar-uploader-span" @click.stop="formData.contactIdNomalPic = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.legalPersonIsContact != 1">*</i>联系人证件反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="contactIdReversePicSuccess"
        >
          <img
            v-if="formData.contactIdReversePic"
            :src="formData.contactIdReversePic"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.contactIdReversePic" class="avatar-uploader-span" @click.stop="formData.contactIdReversePic = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.legalPersonIsContact != 1">*</i>业务办理授权函</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="authorizationLetterSuccess"
        >
          <img
            v-if="formData.authorizationLetter"
            :src="formData.authorizationLetter"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.authorizationLetter" class="avatar-uploader-span" @click.stop="formData.authorizationLetter = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
    </div>
    <div class="bottom" v-if="pageType == 'edit'">
      <el-button class="back" type="primary" @click="$router.go(-1)"
        >返回</el-button
      >
      <el-button @click="onSubmit" class="next" type="primary">保存</el-button>
    </div>
    <div class="bottom" v-else>
      <el-button @click="toUrl('add_shoplist_js')" class="next" type="primary"
        >下一步</el-button
      >
    </div>
  </div>
</template>
<script>
import { URL_API } from "@/utils/apiUrl.js";
import { mapState, mapMutations } from "vuex";
import { merchantsDrop } from "@/api/common.js";
import category from "@/utils/category.json";
import address from "@/utils/address.json";
import { getApi, setApi } from "@/api/user/shopList.js";
import * as imageConversion from "image-conversion";
var legalPersonCemEndDate = null,
  licenceEndDate = null,
  contactValidSEndDate = null;
export default {
  data() {
    return {
      formData: {
        storeName: "", //true	店铺名称
        storeShortName: "", //true	店铺简称
        mchId: "", //true	店铺ID
        internalId: "", //true	内部店铺ID
        servicePhone: "", //true	客服电话
        category: "", //true	经营类目
        storeAddress: "", //true	店铺经营地址
        province: "", //true	省编码
        city: "", //true	市编码
        county: "", //true	区编码
        merchantType: "", //true	店铺主体类型
        licenceType: "", //false	店铺证书类型（店铺主体类型除小微店铺外必填），可选值：12（营业执照）、13（营业执照号码（多合一））、14（事业单位法人证书）、1（统一社会信用代码证书）、2（有偿服务许可证（军队医院适用））、3（医疗机构执业许可证（军队医院适用））、4（企业营业执照（挂靠企业的党组织适用））、5（组织机构代码证（政府机关适用））、6（社会团体法人登记证书）、7（民办非企业单位登记证书）、8（基金会法人登记证书）、9（慈善组织公开募捐资格证书）、10（农民专业合作社法人营业执照）、11（宗教活动场所登记证）、15（其他证书/批文/证明）
        licenceNo: "", //false	证件编号（店铺主体类型除小微店铺外必填）
        companyAddress: "", //false	注册地址（店铺主体类型除小微店铺外必填）
        licenceStartDate: "", //false	证件开始日期，格式yyyy-MM-dd（店铺主体类型除小微店铺外必填）
        licenceEndDate: "", //false	证件结束日期，格式yyyy-MM-dd，可选特殊值forever表示长期（店铺主体类型除小微店铺外必填）
        licencePicture: "", //false	证件图片URL（店铺主体类型除小微店铺外必填）
        licenceCompanyPicture: "", //false	单位正面函照片（店铺主体类型为事业单位/民办非企业单位/社会团体时必填）
        legalPersonCemType: "", //true	法人证件类型，可选值：01（身份证 ）、04（外国护照）09（中国香港居民-来往内地通行证 ）、10（中国澳门居民-来往内地通行证 ）、11（中国台湾居民-来往大陆通行证 ）
        legalPersonName: "", //true	法人姓名
        legalPersonCemNo: "", //true	法人证件号
        legalPersonCemStartDate: "", //true	法人证件开始日期，格式yyyy-MM-dd
        legalPersonCemEndDate: "", //true	法人证件结束日期，格式yyyy-MM-dd，可选特殊值forever表示长期
        idcardNormalPicture: "", //true	法人身份证正面照片URL
        idcardReversePicture: "", //true	法人身份证反面照片URL
        contactName: "", //true	联系人
        contactPhone: "", //true	联系人手机
        contactEmail: "", //true	联系人邮箱
        contactIdCard: "", //true	联系人身份证号码
        contactWechat: "", //false	联系人微信号
        alipayId: "", //false	店铺支付宝账户
        microType: "", //false	小微经营类型（店铺主体类型为小微店铺时必填），可选值：MICRO_TYPE_STORE（店铺场所）、MICRO_TYPE_MOBILE（流动经营/便民服务）、MICRO_TYPE_ONLINE（线上商品/服务交易）
        shopName: "", //false	店铺名称（店铺主体类型为小微店铺时必填）
        shopAddress: "", //false	店铺地址（店铺主体类型为小微店铺时必填）
        shopEntrancePicture: "", //false	店铺环境照片（店铺主体类型为小微店铺时必填）
        shopIndoorPicture: "", //false	店内环境照片（店铺主体类型为小微店铺时必填）
        contactCemType: "", //联系人证件类型

        idAddress:"", //法人证件照地址
        legalPersonIsContact:"",//法人是否为联系人
        legalPersonIsBene:"",//法人是否为受益人
        contactValidStartDate: "", //true	联系人证件开始日期，格式yyyy-MM-dd
        contactValidSEndDate: "", //true	联系人证件结束日期，格式yyyy-MM-dd，可选特殊值forever表示长期

        contactIdNomalPic:"",//联系人证件正面照片
        contactIdReversePic:"",//联系人证件反面照片
        authorizationLetter:"",//业务办理授权函

      },
      PersonOptions: {
        shortcuts: [
          {
            text: "长期",
            onClick(picker) {
              picker.$emit("pick", "2999-01-01");
              legalPersonCemEndDate = "forever";
            },
          },
        ],
      },
      licenceOptions: {
        shortcuts: [
          {
            text: "长期",
            onClick(picker) {
              picker.$emit("pick", "2999-01-01");
              licenceEndDate = "forever";
            },
          },
        ],
      },
      contactValidSEndDateFV:{
        shortcuts: [
          {
            text: "长期",
            onClick(picker) {
              picker.$emit("pick", "2999-01-01");
              contactValidSEndDate = "forever";
            },
          },
        ],
      },
      merchantType: {
        ENTERPRISE: "企业",
        INDIVIDUAL_BUSINESS: "个体工商户",
        MICRO_ENTERPRISE: "小微商户",
        PUBLIC_INSTITUTION: "事业单位",
        PRIVATE_NONENTERPRISE_UNIT: "民办非企业单位",
        SOCIAL_ORGANIZATION: "社会团体",
      },
      legalPersonCemType: {
        "01": "身份证",
        "04": "外国护照",
        "09": "中国香港居民-来往内地通行证",
        10: "中国澳门居民-来往内地通行证",
        11: "中国台湾居民-来往大陆通行证",
      },
      licenceType: [
        "统一社会信用代码证书",
        "有偿服务许可证（军队医院适用）",
        "医疗机构执业许可证（军队医院适用）",
        "企业营业执照（挂靠企业的党组织适用）",
        "组织机构代码证（政府机关适用）",
        "社会团体法人登记证书",
        "民办非企业单位登记证书",
        "基金会法人登记证书",
        "慈善组织公开募捐资格证书",
        "农民专业合作社法人营业执照",
        "宗教活动场所登记证",
        "营业执照",
        "营业执照号码（多合一）",
        "事业单位法人证书",
        "其他证书/批文/证明",
      ],
      microType: {
        MICRO_TYPE_STORE: "店铺场所",
        MICRO_TYPE_MOBILE: "流动经营/便民服务",
        MICRO_TYPE_ONLINE: "线上商品/服务交易",
      },
      mchDrop: [], //店铺下拉
      uploadUrl: URL_API.upload,
      category1Option: category,
      category2Option: {},
      category3Option: "",
      category2: "",
      category3: "",
      provinceOption: address,
      cityOption: [],
      countyOption: [],
      pageType: "", //页面状态
      legalPersonIsBene: {
        1: "是",
        2: "否"
      },
      legalPersonIsContact:{
        1: "是",
        2: "否"
      }
    };
  },
  computed: {
    ...mapState({
      storesApiDetail: "user_storesApiDetail",
    }),
  },
  created() {
    console.log(this.formData.legalPersonIsContact,"ffff")
    console.log(this.formData.legalPersonIsBene,"gggg")
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
      this.getDetail();
    } else {
      if (this.storesApiDetail) {
        this.formData = this.storesApiDetail.storeDetail;
        if (this.formData.legalPersonCemEndDate == "forever") {
          legalPersonCemEndDate = "forever";
          this.formData.legalPersonCemEndDate = "2999-01-01";
        }
        if (this.formData.licenceEndDate == "forever") {
          licenceEndDate = "forever";
          this.formData.licenceEndDate = "2999-01-01";
        }
        if(this.formData.contactValidSEndDate == 'forever'){
          contactValidSEndDate == "forever"
          this.formData.contactValidSEndDate = "2999-01-01";
        }
        this.getAddress();
        this.cateChange();
      }
    }
    this.getDrop();
  },
  methods: {
    ...mapMutations({
      setStoresApi: "user_setStoresApi",
    }),
    // 获取详情
    getDetail() {
      getApi(this.$route.query.id).then((res) => {
        if (res) {
          this.formData = res.resultData.storeDetail;
          this.formData.licenceType = Number(this.formData.licenceType);
          if (this.formData.legalPersonCemEndDate == "forever") {
            legalPersonCemEndDate = "forever";
            this.formData.legalPersonCemEndDate = "2999-01-01";
          }
          if (this.formData.licenceEndDate == "forever") {
            licenceEndDate = "forever";
            this.formData.licenceEndDate = "2999-01-01";
          }
          if(this.formData.contactValidSEndDate == "forever"){
            contactValidSEndDate = 'forever';
            this.formData.contactValidSEndDate ="2999-01-01";
          }
          this.getAddress();
          this.cateChange();
        }
      });
    },
    // 获取地址
    getAddress(active = 0) {
      if (active == 1) {
        this.formData.city = "";
        this.formData.county = "";
        this.cityOption = this.provinceOption.find(
          (v) => v.provinceCode == this.formData.province
        ).citys;
      } else if (active == 2) {
        this.formData.county = "";
        this.countyOption = this.cityOption.find(
          (v) => v.cityCode == this.formData.city
        ).countys;
      } else {
        if (this.formData.province) {
          this.cityOption = this.provinceOption.find(
            (v) => v.provinceCode == this.formData.province
          ).citys;
        }
        if (this.formData.city) {
          this.countyOption = this.cityOption.find(
            (v) => v.cityCode == this.formData.city
          ).countys;
        }
      }
    },
    // 获得种类
    cateChange(active = 0) {
      if (active == 1) {
        this.formData.category = "";
        this.category2Option = this.category1Option[this.category2][0];
        this.category3Option = [];
        this.category3 = "";
      } else if (active == 2) {
        this.formData.category = "";
        this.category3Option = this.category2Option[this.category3];
      } else {
        if (this.formData.category) {
          for (let i in this.category1Option) {
            for (let ind in this.category1Option[i][0]) {
              for (let index in this.category1Option[i][0][ind]) {
                if (
                  this.category1Option[i][0][ind][index]["编号"] ==
                  this.formData.category
                ) {
                  this.category2 = i;
                  this.category2Option = this.category1Option[i][0];
                  this.category3 = ind;
                  this.category3Option = this.category1Option[i][0][ind];
                }
              }
            }
          }
        }
      }
    },
    // 获取下拉菜单
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    selectChanged(value){
      if(value == 1){
        this.formData.contactName = this.formData.legalPersonName;
        this.formData.contactCemType = this.formData.legalPersonCemType;
        this.formData.contactIdCard = this.formData.legalPersonCemNo;
        this.formData.contactValidStartDate = this.formData.legalPersonCemStartDate;
        this.formData.contactValidSEndDate = this.formData.legalPersonCemEndDate;
               
      }else{
        this.formData.contactName = "";
        this.formData.contactCemType = "";
        this.formData.contactIdCard = "";
        this.formData.contactValidStartDate = "";
        this.formData.contactValidSEndDate = "";
      }
    },
    // 店铺证件上传
    licencePictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.licencePicture = res.resultData.fileLink;
      }
    },
    // 身份证正面照片（法人或联系人）上传
    idcardNormalPictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.idcardNormalPicture = res.resultData.fileLink;
      }
    },
    // 身份证反面照片（法人或联系人）上传
    idcardReversePictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.idcardReversePicture = res.resultData.fileLink;
      }
    },
    //单位正面函照片上传
    licenceCompanyPictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.licenceCompanyPicture = res.resultData.fileLink;
      }
    },
    // 开店铺环境照片上传
    shopEntrancePictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.shopEntrancePicture = res.resultData.fileLink;
      }
    },
    // 店内环境照片
    shopIndoorPictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.shopIndoorPicture = res.resultData.fileLink;
      }
    },
    //联系人证件正面照片
    contactIdNomalPicSuccess(res) {
      if (res.resultStatus) {
        this.formData.contactIdNomalPic = res.resultData.fileLink;
      }
    },
    //联系人证件反面照片
    contactIdReversePicSuccess(res) {
      if (res.resultStatus) {
        this.formData.contactIdReversePic = res.resultData.fileLink;
      }
    },
    //业务办理授权函
    authorizationLetterSuccess(res) {
      if (res.resultStatus) {
        this.formData.authorizationLetter = res.resultData.fileLink;
      }
    },

    // 表单规则
    roles() {
      let formData = JSON.parse(JSON.stringify(this.formData));
      if (!formData.merchantType) {
        this.$message.error("主体类型必选");
        return false;
      }
      if (
        formData.merchantType == "ENTERPRISE" ||
        formData.merchantType == "INDIVIDUAL_BUSINESS"
      ) {
        if (!formData.storeName) {
          this.$message.error("店铺名称必填");
          return false;
        }
        if (!formData.storeShortName) {
          this.$message.error("店铺简称必填");
          return false;
        }
        if (!formData.mchId) {
          this.$message.error("所属商户必选");
          return false;
        }
        if (!formData.internalId) {
          this.$message.error("店铺内部ID必填");
          return false;
        }
        if (!formData.servicePhone) {
          this.$message.error("客服电话必填");
          return false;
        }
        if (
          !this.$route.query.id &&
          !/^[0-9\-]+$/.test(formData.servicePhone)
        ) {
          this.$message.error("请正确填写客服电话");
          return;
        }
        if (!formData.category) {
          this.$message.error("经营类目必选");
          return false;
        }
        if (!formData.storeAddress) {
          this.$message.error("店铺经营地址必填");
          return false;
        }
        if (!formData.province) {
          this.$message.error("省份必填");
          return false;
        }
        if (!formData.city) {
          this.$message.error("城市必填");
          return false;
        }
        if (!formData.county) {
          this.$message.error("区县必填");
          return false;
        }
        if (!formData.licenceType) {
          this.$message.error("店铺证件类型必选");
          return false;
        }
        if (!formData.licenceNo) {
          this.$message.error("证件编号必填");
          return false;
        }
        if (!formData.companyAddress) {
          this.$message.error("注册地址必填");
          return false;
        }
        if (!formData.licenceStartDate) {
          this.$message.error("营业执照开始日期必选");
          return false;
        }
        if (!formData.licenceEndDate && licenceEndDate != "2999-01-01") {
          this.$message.error("营业执照结束日期必选");
          return false;
        }
        if (!formData.legalPersonCemType) {
          this.$message.error("法人证件类型必选");
          return false;
        }
        if (!formData.legalPersonName) {
          this.$message.error("法人姓名必填");
          return false;
        }
        if (!formData.legalPersonCemNo) {
          this.$message.error("法人证件号码必填");
          return false;
        }
        if (formData.merchantType == "ENTERPRISE"  ) {
          if (!formData.idAddress) {
            this.$message.error("法人证件照地址必填");
            return false;
          }
        }
        if (!formData.legalPersonCemStartDate) {
          this.$message.error("法人证件开始日期必选");
          return false;
        }
        if (
          !formData.legalPersonCemEndDate &&
          legalPersonCemEndDate != "2999-01-01"
        ) {
          this.$message.error("法人证件结束日期必选");
          return false;
        }
        if (!formData.contactPhone) {
          this.$message.error("联系人手机必填");
          return false;
        }
        if (
          !this.$route.query.id &&
          !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(formData.contactPhone)
        ) {
          this.$message.error("请正确填写手机号码");
          return;
        }
        if (!formData.contactName) {
          this.$message.error("联系人必填");
          return false;
        }
        if (!formData.contactCemType) {
          this.$message.error("联系人证件类型必选");
          return false;
        }
        if (!formData.contactIdCard) {
          this.$message.error("联系人证件号码必填");
          return false;
        }
        if (!formData.contactEmail) {
          this.$message.error("联系人邮箱必填");
          return false;
        }
        if (!formData.contactValidStartDate) {
          this.$message.error("联系人证件开始日期必选");
          return false;
        }
        if (
          !formData.contactValidSEndDate &&
          contactValidSEndDate != "2999-01-01"
        ) {
          this.$message.error("联系人证件结束日期必选");
          return false;
        }
        if (!formData.licencePicture) {
          this.$message.error("营业执照必传");
          return false;
        }
        if (!formData.idcardNormalPicture) {
          this.$message.error("法人身份证正面照片必传");
          return false;
        }
        if (!formData.idcardReversePicture) {
          this.$message.error("法人身份证反面照片必传");
          return false;
        }
      } else if (formData.merchantType == "MICRO_ENTERPRISE") {
        if (!formData.storeName) {
          this.$message.error("店铺名称必填");
          return false;
        }
        if (!formData.storeShortName) {
          this.$message.error("店铺简称必填");
          return false;
        }
        if (!formData.mchId) {
          this.$message.error("所属商户必选");
          return false;
        }
        if (!formData.internalId) {
          this.$message.error("店铺内部ID必填");
          return false;
        }
        if (!formData.servicePhone) {
          this.$message.error("客服电话必填");
          return false;
        }
        if (
          !this.$route.query.id &&
          !/^[0-9\-]+$/.test(formData.servicePhone)
        ) {
          this.$message.error("请正确填写客服电话");
          return;
        }
        if (!formData.category) {
          this.$message.error("经营类目必选");
          return false;
        }
        if (!formData.storeAddress) {
          this.$message.error("店铺经营地址必填");
          return false;
        }
        if (!formData.province) {
          this.$message.error("省份必填");
          return false;
        }
        if (!formData.city) {
          this.$message.error("城市必填");
          return false;
        }
        if (!formData.county) {
          this.$message.error("区县必填");
          return false;
        }
        if (!formData.microType) {
          this.$message.error("小微经营类型必选");
          return false;
        }
        if (!formData.shopName) {
          this.$message.error("店铺名称必填");
          return false;
        }
        if (!formData.shopAddress) {
          this.$message.error("店铺地址必填");
          return false;
        }
        if (!formData.legalPersonCemType) {
          this.$message.error("法人证件类型必选");
          return false;
        }
        if (!formData.legalPersonName) {
          this.$message.error("法人姓名必填");
          return false;
        }
        if (!formData.legalPersonCemNo) {
          this.$message.error("法人证件号码必填");
          return false;
        }
        if (!formData.legalPersonCemStartDate) {
          this.$message.error("法人证件开始日期必选");
          return false;
        }
        if (
          !formData.legalPersonCemEndDate &&
          legalPersonCemEndDate != "2999-01-01"
        ) {
          this.$message.error("法人证件结束日期必选");
          return false;
        }
        if (!formData.contactName) {
          this.$message.error("联系人必填");
          return false;
        }
        if (!formData.contactPhone) {
          this.$message.error("联系人手机必填");
          return false;
        }
        if (
          !this.$route.query.id &&
          !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(formData.contactPhone)
        ) {
          this.$message.error("请正确填写手机号码");
          return;
        }
        if (!formData.contactEmail) {
          this.$message.error("联系人邮箱必填");
          return false;
        }
        if (!formData.contactCemType) {
          this.$message.error("联系人证件类型必选");
          return false;
        }
        if (!formData.contactIdCard) {
          this.$message.error("联系人证件号码必填");
          return false;
        }
        if (!formData.contactValidStartDate) {
          this.$message.error("联系人证件开始日期必选");
          return false;
        }
        if (
          !formData.contactValidSEndDate &&
          contactValidSEndDate != "2999-01-01"
        ) {
          this.$message.error("联系人证件结束日期必选");
          return false;
        }
        if (!formData.shopEntrancePicture) {
          this.$message.error("店铺门头照片必传");
          return false;
        }
        if (!formData.shopIndoorPicture) {
          this.$message.error("店内环境照片必传");
          return false;
        }
        if (!formData.idcardNormalPicture) {
          this.$message.error("法人身份证正面照片必传");
          return false;
        }
        if (!formData.idcardReversePicture) {
          this.$message.error("法人身份证反面照片必传");
          return false;
        }
      } else {
        if (!formData.storeName) {
          this.$message.error("店铺名称必填");
          return false;
        }
        if (!formData.storeShortName) {
          this.$message.error("店铺简称必填");
          return false;
        }
        if (!formData.mchId) {
          this.$message.error("所属商户必选");
          return false;
        }
        if (!formData.internalId) {
          this.$message.error("店铺内部ID必填");
          return false;
        }
        if (!formData.servicePhone) {
          this.$message.error("客服电话必填");
          return false;
        }
        if (
          !this.$route.query.id &&
          !/^[0-9\-]+$/.test(formData.servicePhone)
        ) {
          this.$message.error("请正确填写客服电话");
          return;
        }
        if (!formData.category) {
          this.$message.error("经营类目必选");
          return false;
        }
        if (!formData.storeAddress) {
          this.$message.error("店铺经营地址必填");
          return false;
        }
        if (!formData.province) {
          this.$message.error("省份必填");
          return false;
        }
        if (!formData.city) {
          this.$message.error("城市必填");
          return false;
        }
        if (!formData.county) {
          this.$message.error("区县必填");
          return false;
        }
        if (!formData.licenceType) {
          this.$message.error("店铺证件类型必选");
          return false;
        }
        if (!formData.licenceNo) {
          this.$message.error("证件编号必填");
          return false;
        }
        if (!formData.companyAddress) {
          this.$message.error("注册地址必填");
          return false;
        }
        if (!formData.licenceStartDate) {
          this.$message.error("登记证件开始日期必选");
          return false;
        }
        if (!formData.licenceEndDate && licenceEndDate != "2999-01-01") {
          this.$message.error("登记证件结束日期必选");
          return false;
        }
        if (!formData.legalPersonCemType) {
          this.$message.error("法人证件类型必选");
          return false;
        }
        if (!formData.legalPersonName) {
          this.$message.error("法人姓名必填");
          return false;
        }
        if (!formData.legalPersonCemNo) {
          this.$message.error("法人证件号码必填");
          return false;
        }
        if (!formData.legalPersonCemStartDate) {
          this.$message.error("法人证件开始日期必选");
          return false;
        }
        if (
          !formData.legalPersonCemEndDate &&
          legalPersonCemEndDate != "2999-01-01"
        ) {
          this.$message.error("法人证件结束日期必选");
          return false;
        }
        if (!formData.contactName) {
          this.$message.error("联系人必填");
          return false;
        }
        if (!formData.contactPhone) {
          this.$message.error("联系人手机必填");
          return false;
        }
        if (
          !this.$route.query.id &&
          !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(formData.contactPhone)
        ) {
          this.$message.error("请正确填写手机号码");
          return;
        }
        if (!formData.contactEmail) {
          this.$message.error("联系人邮箱必填");
          return false;
        }
        if (!formData.contactCemType) {
          this.$message.error("联系人证件类型必选");
          return false;
        }
        if (!formData.contactIdCard) {
          this.$message.error("联系人证件号码必填");
          return false;
        }
        if (!formData.contactValidStartDate) {
          this.$message.error("联系人证件开始日期必选");
          return false;
        }
        if (
          !formData.contactValidSEndDate &&
          contactValidSEndDate != "2999-01-01"
        ) {
          this.$message.error("联系人证件结束日期必选");
          return false;
        }
        if (!formData.licencePicture) {
          this.$message.error("证件照片必传");
          return false;
        }
        if (!formData.licenceCompanyPicture) {
          this.$message.error("单位证明函必传");
          return false;
        }
        if (!formData.idcardNormalPicture) {
          this.$message.error("法人身份证正面照片必传");
          return false;
        }
        if (!formData.idcardReversePicture) {
          this.$message.error("法人身份证反面照片必传");
          return false;
        }
      }
      if(formData.legalPersonIsContact != 1){
        if (!formData.contactIdNomalPic){
          this.$message.error("联系人证件正面照片必传");
          return false;
        }
        if (!formData.contactIdReversePic){
          this.$message.error("联系人证件反面照片必传");
          return false;
        }
        if (!formData.authorizationLetter){
          this.$message.error("业务办理授权函必传");
          return false;
        }
      }

      return true;
    },
    // 页面跳转
    toUrl(name) {
      if (!this.roles()) {
        return;
      }
      this.$router.push({
        name: name,
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
          InfoType: this.$route.query.InfoType,
        },
      });
    },
    //提交
    onSubmit() {
      if (!this.roles()) {
        return;
      }
      let formData = JSON.parse(JSON.stringify(this.formData));
      console.log(this.formData)
      if (formData.legalPersonCemEndDate == "2999-01-01" && legalPersonCemEndDate == "forever") {
        formData.legalPersonCemEndDate = "forever";
      }
      if (formData.licenceEndDate == "2999-01-01" && licenceEndDate == "forever") {
        formData.licenceEndDate = "forever";
      }
      if(formData.contactValidSEndDate == '2999-01-01' && contactValidSEndDate == "forever"){
        formData.contactValidSEndDate = "forever";
      }
      setApi(this.$route.query.id, formData).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.$router.go(-1);
        }
      });
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ;
      if (!extension) {
        this.$message.error("上传图片仅支持jpg/jpeg/png格式");
      }
      if (!extension) {
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    },
    //小微商户切到其他店铺主体类型，店铺证件类型为null时，店铺证件类型显示为0，优化成为空
    merchantTypeChange(){
      if(this.formData.licenceType == 0){
        this.formData.licenceType = '';
      }
    },
  },
  beforeDestroy() {
    let formData = JSON.parse(JSON.stringify(this.formData));
    if (formData.legalPersonCemEndDate == "2999-01-01" || legalPersonCemEndDate == "forever") {
      formData.legalPersonCemEndDate = "forever";
    }
    if (formData.licenceEndDate == "2999-01-01" || licenceEndDate == "forever") {
      formData.licenceEndDate = "forever";
    }
    if(formData.contactValidSEndDate == '2999-01-01' || contactValidSEndDate == "forever"){
      formData.contactValidSEndDate = "forever";
    }
    let data = {
      storeDetail: formData,
      account: this.storesApiDetail ? this.storesApiDetail.account : null,
      config: this.storesApiDetail ? this.storesApiDetail.config : null,
      beneInfos:this.storesApiDetail ? this.storesApiDetail.beneInfos : null,
    };
    this.setStoresApi(data);
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/editInput.css";
.tab1{
  min-height: auto !important;
}
.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  font-size: 12px;
  padding: 0;
}

.bottom {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 18px;
}

.djsc {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
}

.upload_wj_right {
  float: left;
  margin-left: 200px;
  width: 22%;
  height: 100%;
}

.upload_wj h5 {
  padding: 29px 0 19px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.upload_wj_left {
  float: left;
  margin-left: 44px;
  width: 22%;
  height: 100%;
}

.upload_wj {
  margin-bottom: 25px;
  width: 100%;
  height: 180px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
}

.uploader1 {
  margin-left: 30px;
  float: left;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 i {
  color: #48b8b6;
  padding-right: 3px;
}

.uploader2 {
  margin-left: 30px;
  margin-right: 19px;
  float: left;
}

.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload_ph p span i {
  color: #48b8b6;
  padding-right: 3px;
}

.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.upload_ph {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 4px;
    display: flex;
    flex-flow: wrap;
    padding-bottom: 20px;
}

.ul_right {
  float: right;
  height: 100%;
  width: calc(100% - 526px);
  text-align: right;
  margin-right: 28px;
}

.ul_left {
  float: left;
  width: 498px;
  height: 100%;
  text-align: right;
}
</style>
